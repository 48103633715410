import React from 'react';
import ReactDOM from 'react-dom';
import {Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import './index.css';
import App from './App';
import Landing from "./pages/Landing/Landing";
import NotFound from "./pages/NotFound/NotFound";
import Italy from './pages/Italy/Italy'
import WhoWeAre from "./pages/WhoWeAre/WhoWeAre";
import WhatWeDo from "./pages/WhatWeDo/WhatWeDo";
import WhereWeGo from "./pages/WhereWeGo/WhereWeGo";
import SampleItineraries from './pages/SampleItineraries/SampleItineraries'
import * as serviceWorker from './serviceWorker';
import ContactUs from "./pages/ContactUs/ContactUs";


const routing = (

    <Router>
        <App>
        <Switch>
            <Route  exact path={"/"} component={Landing} />
            <Route path={"/italy"} component={Italy} />
            <Route path={"/sample-itineraries"} component={SampleItineraries}/>
            <Route path={"/who-we-are"} component={WhoWeAre} />
            <Route path={"/what-we-do"} component={WhatWeDo} />
            <Route path={"/where-we-go"} component={WhereWeGo} />
            <Route path={"/contact-us"} component={ContactUs} />
            <Route path={"/itineraries"} component={SampleItineraries} />
            <Route component={NotFound}/>
        </Switch>

        </App>

    </Router>
)

ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
