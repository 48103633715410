import React from "react";
import ContactUsForm from "../../forms/ContactUsForm";

class ContactUs extends React.Component {
  render() {
    return (
      <div className={"container-flex top-div"}>
        <ContactUsForm />
      </div>
    );
  }
}

export default ContactUs;
