import React from "react";
import Itinerary from "../../components/Itinerary/Itinerary";
import styles from "./SampleItineraries.module.css";

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

class SampleItineraries extends React.Component {
  state = {
    firstName: "",
    lastName: "",
    email: "",
    userHasSubmitted: false
  };

  componentDidMount() {
    const email = localStorage.getItem("email");
    if (email) {
      this.setState({ email, userHasSubmitted: true });
    }
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "view itinerary", ...this.state })
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error));

    const { email } = this.state;
    localStorage.setItem("email", email);
    localStorage.setItem("userHasSumitted", true);
    this.setState({ userHasSubmitted: true });

    e.preventDefault();
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value });

  render() {
    const { firstName, lastName, email } = this.state;
    const htmlForm = (
      <form
        onSubmit={this.handleSubmit}
        method="post"
        className={styles.formBody}
      >
        <input type="hidden" name="form-name" value="view itinerary" />
        <div className={styles.formHeaderFont}>
          Please enter your contact details below to view our famous itineraries.
        </div>
        <div className={styles.wrapper}>
          <div className={styles.verticalStack}>
            <div className={styles.inputField}>
              <input
                className={styles.inputText}
                name="firstName"
                value={firstName}
                placeholder={"first name..."}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <input
                className={styles.inputText}
                name="lastName"
                value={lastName}
                placeholder={"last name..."}
                onChange={this.handleChange}
                required
              />
            </div>
            <div className={styles.inputField}>
              <input
                className={styles.inputText}
                name="email"
                value={email}
                type="email"
                placeholder={"email..."}
                onChange={this.handleChange}
                required
              />
            </div>
          </div>
        </div>
        <div className={styles.submitBtnContainer}>
          <button type="submit" className={styles.buttonStyle}>
            Submit
          </button>
        </div>
      </form>
    );

    return (
      <div className={"container-flex top-div"}>
        {!this.state.userHasSubmitted && htmlForm}
        {this.state.userHasSubmitted && <Itinerary />}
      </div>
    );
  }
}

export default SampleItineraries;
