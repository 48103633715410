import React from "react";
import Button from "../Button/Button";
import styles from "./Itinerary.module.css";

const Itinerary = () => {
  return (
    <div className={styles.itineraryContainer}>
      <div className={styles.verticalStack}>
        <h1 className={styles.headingFont}>View our famous itineraries!</h1>
        <h3 className={styles.subText}>
          Here is a selection of some of our most popular trips
        </h3>

        <div className={styles.spaceBelow}>
          <Button
            styleOption={"purple"}
            target={"_blank"}
            link={
              "https://myeuropeanvacay.s3.amazonaws.com/itineraries/ITALY+TRIP+SEPTEMBER+2018++(17TH-30TH)+texas.docx.pdf"
            }
          >
            Trip to Italy
          </Button>
        </div>
        <div className={styles.spaceBelow}>
          <Button
            styleOption={"green"}
            target={"_blank"}
            link={
              "https://myeuropeanvacay.s3.amazonaws.com/itineraries/Croatia+trip.docx.pdf"
            }
          >
            Trip to Croatia
          </Button>
        </div>
        <div className={styles.spaceBelow}>
          <Button
            styleOption={"red"}
            target={"_blank"}
            link={
              "https://myeuropeanvacay.s3.amazonaws.com/itineraries/TRIP+TO+EAST+EUROPE+2018+%2C+July.docx.pdf"
            }
          >
            Trip to Eastern Europe
          </Button>
        </div>
        <div className={styles.spaceBelow}>
          <Button
            styleOption={"purple"}
            target={"_blank"}
            link={
              "https://myeuropeanvacay.s3.amazonaws.com/itineraries/Montenegro+-Dubrovnik-Verona-Italian+Riviea-Lake+Como+and+Garda.docx.pdf"
            }
          >
            Trip to Montenegro
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Itinerary;
