import React from "react";
import ShowCaseImage from "../../components/ShowCaseImage/ShowCaseImage";
import contentData from "../../content/Landing.json";
import SectionBlurb from "../../components/SectionBlurb/SectionBlurb";
import styles from "./Landing.module.css";

const Landing = () => {
  return (
    <>
      <ShowCaseImage />
      <div className={styles.spacer}></div>
      {contentData.SECTIONS.map((section, i) => {
        return (
          <div key={i} className={"container-flex"}>
            <SectionBlurb sectionContent={section} displayNumber={i} />
          </div>
        );
      })}
    </>
  );
};

export default Landing;
