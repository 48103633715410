import React from "react";
import styles from "./SectionBlurb.module.css";
import classnames from "classnames";

import wwaImage from "../../assets/MEVPhotos/Greece/Greece6.jpg";
import Button from "../Button/Button";
import clientsImage from "../../assets/MEVPhotos/Clients/Clients.jpg";
import wwgImage from "../../assets/MEVPhotos/Italy/Italy8.jpg";

const imageAssets = {
  "Who we are": wwaImage,
  "What we do": clientsImage,
  "Where we go": wwgImage
};

const SectionBlurb = props => {
  const { sectionContent, displayNumber } = props;
  const returnColor = i => {
    if (!!(i % 2)) {
      return "green";
    }
    return "purple";
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.verticalStack}>
        <div className={styles.headingFont}>{sectionContent.HEADING}</div>
        <div
          className={classnames(styles.blurbFont, returnColor(displayNumber))}
        >
          {sectionContent.BLURB}
        </div>
        <div className={styles.cardLink}>
          <Button styleOption={'green'} link={sectionContent.URL}>{sectionContent.LINK_TEXT}</Button>
        </div>
      </div>
      <div className={styles.imgCol}>
        <img
          className={styles.image}
          alt="euro"
          src={imageAssets[sectionContent.HEADING]}
        />
      </div>
    </div>
  );
};

export default SectionBlurb;
