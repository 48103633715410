import React from "react";
import classnames from "classnames";
import styles from "./AboutPersonCard.module.css";

const backgroundColors = {
  green: styles.greenBG,
  red: styles.redBG
};

const AboutPersonCard = props => {
  const {
    personName,
    personBlurb,
    personImgUrl,
    backgroundStyleOption
  } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgCol}>
        <img src={personImgUrl} alt={personName} className={styles.image} />
      </div>
      <div
        className={classnames(
          styles.verticalStack,
          backgroundColors[backgroundStyleOption]
        )}
      >
        <div className={styles.headerFont}>{personName}</div>
        <div className={styles.blurbFont}>{personBlurb}</div>
      </div>
    </div>
  );
};


export default AboutPersonCard;
