import React from "react";
import Button from "../../components/Button/Button";
import AboutPersonCard from "../../components/AboutPersonCard/AboutPersonCard";
import data from "../../content/WhoWeAre";

import styles from "./WhoWeAre.module.css";

const WhoWeAre = () => {
  return (
    <div className={styles.spacer}>
      <div className="container-flex">
        <div className={styles.verticalStack}>
          <div className={styles.headerFont}>Who we are</div>
          <div className={styles.introBlurbFont}>
            {data.INTRO_TEXT}
          </div>
          {data.EMPLOYEES.map((person, index) => {
            const backgroundStyleOption = index % 2 === 0 ? "green" : "red";
            return (
              <AboutPersonCard
                personName={person.NAME}
                personBlurb={person.ABOUT}
                personImgUrl={person.HEADSHOT_URL}
                backgroundStyleOption={backgroundStyleOption}
              />
            );
          })}

          <div className={styles.btnSpacer}>
            <Button link={"/contact-us"} styleOption={"purple"}>
              CONTACT US
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhoWeAre;

//
// <div
//             className={classnames(styles.personHeaderFont, styles.divSpacer)}
//           >
//             ANDREA TRAINA
//           </div>
//           <div className={styles.personBlurbFont}>
//             While living in Alabama, Andrea found many connections who were
//             interested in learning what he had to share about the Italian
//             culture and language. He began teaching private Italian lessons, but
//             much of the time in the class was taken up with questions about
//             Italy, and someone suggested a workshop on weekends about getting
//             ready for an Italian trip. So, in September 2011, he headed to Italy
//             with his first tour group, and his career as a travel guide was
//             born. Today, Andrea shares his passion for and knowledge of Italy
//             and Europe as a whole with his guided tours.
//           </div>
//           <div
//             className={classnames(styles.personHeaderFont, styles.divSpacer)}
//           >
//             ALESSANDRO SCHERMA
//           </div>
//           <div className={styles.personBlurbFont}>
//             You’ll enjoy traveling with Alex, a capable Sicilian guy. Cheerful
//             and funny, he is a helper, always striving to make you at ease. A
//             former head waiter in high-class locations in the UK, he decided to
//             come back to homeland Sicily and work alongside Andrea at My
//             European Vacay.{" "}
//           </div>
//           <div
//             className={classnames(styles.personHeaderFont, styles.divSpacer)}
//           >
//             Francesco Doro
//           </div>
//           <div className={styles.personBlurbFont}>
//             Francesco is a young, dynamic winemaker who travels the wine world.
//             While not walking the vineyards, he enjoys accompanying you
//             throughout his favorite places in Europe, and knows the best places
//             to go for shopping!
//           </div>
//           <div
//             className={classnames(styles.personHeaderFont, styles.divSpacer)}
//           >
//             FRANCESCO CICCIO
//           </div>
//           <div className={styles.personBlurbFont}>
//             Francesco is another handsome Sicilian guy who likes photography,
//             loves wine, and worships the many beautiful landscapes he’ll drive
//             you to.{" "}
//           </div>
