import React from "react";
import styles from "./CountryCard.module.css";
import classnames from "classnames";
import Button from "../Button/Button";

import italyImage from "../../assets/MEVPhotos/Italy/Italy13.jpg";
import franceImage from "../../assets/MEVPhotos/France/France3.jpg";
import spainImage from "../../assets/MEVPhotos/Spain/Spain.jpg";
import greeceImage from "../../assets/MEVPhotos/Greece/Greece6.jpg";

const images = {
  Italy: italyImage,
  France: franceImage,
  Spain: spainImage,
  Greece: greeceImage
};

const backgroundColors = {
  green: styles.greenBG,
  red: styles.redBG
};

const CountryCard = props => {
  const {
    countryName,
    cardText,
    btnStyleOptions,
    link
  } = props;
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgCol}>
        <img
          src={images[countryName]}
          alt={countryName}
          className={styles.image}
        />
      </div>
      <div
        className={classnames(
          styles.verticalStack,
          backgroundColors[btnStyleOptions]
        )}
      >
        <div className={styles.headerFont}>{countryName}</div>
        <div className={styles.blurbFont}>{cardText}</div>
        <div className={styles.ctaBtn}>
          <Button link={link} styleOption={btnStyleOptions}>
            VIEW ITINERARIES
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CountryCard;

//
//
// <div className={styles.container}>
//       <div className={styles.wrapper}>
//         <div className={styles.leftCol}>
//
//             <div className={classnames(styles.verticalStack)}>
//               <img
//                 style={{ width: "100%" }}
//                 alt={"some image here"}
//                 src={images[countryName]}
//               />
//               <div className={styles.hiderBig}>
//                 <div className={styles.footer}>
//                   <a
//                     className={styles.expanderButton}
//                     onClick={() => {
//                       expanderMethod(countryName);
//                     }}
//                   >
//                     <img src={expandSvg} alt="expand" />
//                   </a>
//                   <span>{countryName}</span>
//                 </div>
//                 {expandedCountry === countryName && (
//                   <div
//                     className={classnames(
//                       styles.expandedCardBox,
//                       styles.cardText
//                     )}
//                   >
//                     <div>{cardText}</div>
//                     <div>
//                       <a
//                         onClick={() => {
//                           closeMethod();
//                         }}
//                       >
//                         close card
//                       </a>
//                     </div>
//                   </div>
//                 )}
//                 <div className={styles.collapseButton}>button to close blurb</div>
//               </div>
//             </div>
//
//         </div>
//         <div className={styles.rightCol}>
//           <div className={styles.verticalStack}>
//             <h1>{countryName}</h1>
//             <div style={{marginBottom: "30px"}}>{cardText}</div>
//             <Button styleOption={btnStyleOptions}>
//               VIEW INTINERARIES
//             </Button>
//           </div>
//         </div>
//       </div>
//     </div>
//
//
//
//
//
//
//
//

//
// <div className={styles.flexContainer}>
//             <div className={styles.wrapper}>
//                 <div className={classnames(styles.flexCol, styles.colWithRows)}>
//                     <div className={styles.verticalStack}>
//                         <img  style={{width: "100%"}} alt={'some image here'} src={imgURL} />
//                             <div className={styles.footer}>
//                                 <a className={styles.expanderButton} onClick={() => {expanderMethod(countryName)}}>
//                                     <img src={expandSvg} alt="expand" />
//                                 </a>
//                                 <span>{countryName}</span>
//                             </div>
//                         {expandedCountry === countryName && (
//                             <div className={classnames(styles.expandedCardBox, styles.cardText)}>
//                                 <div>
//                                     {cardText}
//                                 </div>
//                                 <div>
//                                 <a onClick={() => {closeMethod()}}>
//                                     close card
//                                 </a>
//                                 </div>
//                             </div>
//                         )}
//                         <div>action button!</div>
//                         <div>action button</div>
//                         <div className={styles.collapseButton}>
//                             button to close blurb
//                         </div>
//                     </div>
//                 </div>
//
//             </div>
//         </div>
