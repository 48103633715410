import React from 'react'
import Button from '../../components/Button/Button'


const Italy = () => {

    return (
        <div className={'containerFlex'}>
            Italy
            <Button styleOption={'btnGreen'}>
                Text
            </Button>
        </div>
    )
}

export default Italy