import React from "react";

import DrawerToggleButton from "../SideDrawer/DrawerToggleButton";
import styles from "./Navbar.module.css";

const Navbar = props => {
  return (
    <header className={styles.navbar}>
      <nav className={styles.navbarBody}>
        <div>
          <div className={styles.navbarToggleButton}>
            <DrawerToggleButton click={props.drawerClickHandler} />
          </div>
        </div>
        <div className={styles.spacer} />
        <div className={styles.navbarItems}>
          <ul>{props.children}</ul>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
