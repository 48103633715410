import React from "react";
import styles from "./ShowCaseImage.module.css";

const ShowCaseImage = () => {
  return (
    <div className={styles.showCaseContainer}>

      <div className={styles.testLanding}><div className={styles.featureFont}>
        <span style={{ fontWeight: "bold"}}>My European Vacay</span>
      </div></div>
    </div>
  );
};

export default ShowCaseImage;
