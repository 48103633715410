import React from "react";

import CountryCard from "../../components/CountryCard/CountryCard";
import content from "../../content/WhereWeGo";
import styles from "./WhereWeGo.module.css";
import classnames from "classnames";

class WhereWeGo extends React.PureComponent {
  render() {
    return (
      <>
        <div className={"container-flex top-div"}>
          <div className={styles.introduction}>
            <h1 className={classnames("small-header", styles.pageHeader)}>
              WHERE WE GO
            </h1>
            <div className={styles.introductionText}>
              {content.INTRODUCTION}
            </div>
          </div>
        </div>
        {content.DESTINATIONS.map((destination, i) => {
          return (
            <div className={classnames("container-flex")}>
              <CountryCard
                countryName={destination.TITLE}
                cardText={destination.BLURB}
                btnStyleOptions={!!(i % 2) ? "green" : "red"}
                link={"/itineraries"}
              />
            </div>
          );
        })}
      </>
    );
  }
}

export default WhereWeGo;
