import React from "react";
import { NavLink } from "react-router-dom";

import Navbar from "./components/NavBar/Navbar";
import SideDrawer from "./components/SideDrawer/SideDrawer";
import Backdrop from "./components/Backdrop/Backdrop";
import Footer from "./components/Footer/Footer";

import styles from "./App.module.css";

class App extends React.Component {
  state = {
    sideDrawerOpen: false
  };

  toggleDrawer = () => {
    this.setState({
      sideDrawerOpen: !this.state.sideDrawerOpen
    });
  };

  closeDrawer = () => {
    console.log("calling close");
    this.setState({
      sideDrawerOpen: false
    });
  };

  render() {
    console.log(this.state.sideDrawerOpen);

    let backdrop;
    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.closeDrawer} />;
    }

    return (
      <>
        <div className="App" style={{ height: "100%" }}>
          <Navbar drawerClickHandler={this.toggleDrawer}>
            <li>
              <NavLink exact activeClassName={"active"} to={"/"}>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName={"active"} to={"/where-we-go"}>
                Destinations
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName={"active"} to={"/what-we-do"}>
                Our Services
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName={"active"} to={"/who-we-are"}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName={"active"} to={"/contact-us"}>
                Contact Us
              </NavLink>
            </li>
            <li>
              <NavLink exact activeClassName={"active"} to={"/itineraries"}>
                Itineraries
              </NavLink>
            </li>
          </Navbar>
          <SideDrawer
            show={this.state.sideDrawerOpen}
            click={this.closeDrawer}
          />
          {backdrop}
          <main className={styles.mainContentContainer}>
            {this.props.children}
          </main>
          <Footer />
        </div>
      </>
    );
  }
}

export default App;
