import React from "react";

import styles from "./DrawerToggleButton.module.css";

const DrawerToggleButton = props => {
  return (
    <button className={styles.toggleButton} onClick={props.click}>
      <div className={styles.toggleButtonLine}></div>
      <div className={styles.toggleButtonLine}></div>
      <div className={styles.toggleButtonLine}></div>
    </button>
  );
};

export default DrawerToggleButton;
