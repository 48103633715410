import React from "react";
import styles from "./ContactUsForm.module.css";


const ContactUsForm = () => {
  return (
    <form name="contact" method="post" className={styles.formBody}>
      <input type="hidden" name="form-name" value="contact" />
      <div className={styles.formHeaderFont}>
        Please enter your details below to receive information on upcoming
        trips, or to ask Andrea a question.
      </div>
      <div className={styles.wrapper}>
        <div className={styles.verticalStack}>
          <div className={styles.inputField}>
            <input
              className={styles.inputText}
              name="firstName"
              placeholder={"first name..."}
              required
            />
          </div>
          <div className={styles.inputField}>
            <input
              className={styles.inputText}
              name="lastName"
              placeholder={"last name..."}
              required
            />
          </div>
          <div className={styles.inputField}>
            <input
              className={styles.inputText}
              name="email"
              placeholder={"email..."}
              type="email"
              required
            />
          </div>
        </div>

        <div className={styles.questionColumn}>
          <div className={styles.inputField}>
            <textarea
              className={styles.questionBox}
              name="questions"
              placeholder={"questions..."}
              required
            />
          </div>
        </div>
      </div>
      <div className={styles.submitBtnContainer}>
        <button type="submit" className={styles.buttonStyle}>
          Submit
        </button>
      </div>
    </form>
  );
};

export default ContactUsForm;
