import React from "react";
import classnames from "classnames";
import styles from "./Button.module.css";

const styleOptions = {
  purple: styles.btnPurple,
  green: styles.btnGreen,
  red: styles.btnRed
};

const Button = props => {
  const { link, target} = props;
  const extras = {}
  if(target){
      extras.target = target
  }
  return (
    <a
      className={classnames(styles.baseButton, styleOptions[props.styleOption])}
      href={link}
      {...extras}
    >
      {props.children}
    </a>
  );
};

export default Button;
