import React from 'react';
import styles from "./Backdrop.module.css"


const Backdrop = (props) => {
    return (
        <div onClick={props.click} className={styles.backdrop}/>
    )
}

export default Backdrop