import React from "react";
import Button from "../../components/Button/Button";
import contents from "../../content/WhatWeDo";
import styles from "./WhatWeDo.module.css";

import pisa from "../../assets/MEVPhotos/Clients/Clients4.jpg";
import classnames from "classnames";

const WhatWeDo = () => {
  return (
    <div className={styles.spacer}>
      <div className={classnames("container-flex")}>
        <div className={styles.wrapper}>

          <div className={classnames(styles.verticalStack, styles.greenBG)}>
            <div className={styles.headerFont}>{"What we do"}</div>
            <div className={styles.blurbFont}>{contents.CONTENTS}</div>
            <div className={classnames(styles.ctaBtn, styles.btnSpacer)}>
              <Button link={"/itineraries"} className={styles.btnSpacer} styleOption={"green"}>VIEW INTINERARIES</Button>
            </div>
            <div className={classnames(styles.ctaBtn, styles.btnSpacer)}>
              <Button link={"/contact-us"} styleOption={"purple"}>CONTACT US</Button>
            </div>
          </div>
          <div className={styles.containerResponsive}>
            <img src={pisa} alt={"Pisa Tower"}  />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
