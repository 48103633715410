import React from "react";
import facebook from "../../assets/menu/facebook.svg";
import instagram from "../../assets/menu/instagram.svg";
import email from "../../assets/menu/email.svg";

import styles from "./SideDrawer.module.css";

const SideDrawer = props => {
  let drawerClasses = styles.sideDrawer;
  if (props.show) {
    drawerClasses = `${styles.sideDrawer} ${styles.open}`;
  }

  return (
    <nav className={drawerClasses}>
      <div className={styles.sideDrawerContentContainer}>

        <ul>
          <li>
            <a href={"/"}>Home</a>
          </li>
          <li>
            <a href={"/where-we-go"}>Destinations</a>
          </li>
          <li>
            <a href={"/what-we-do"}>Our Services</a>
          </li>
          <li>
            <a href={"/who-we-are"}>About Us</a>
          </li>
          <li>
            <a href={"/contact-us"}>Contact Us</a>
          </li>
          <li>
            <a href={"/itineraries"}>Itineraries</a>
          </li>
        </ul>
        <div className={styles.social}>
          <a href="https://facebook.com/andrea.traina.50" target={"_blank"}>
            <img className={styles.iconSize} src={facebook} alt={"facebook"} />
          </a>
          <a href="https://instagram.com/myeuropeanvacay/" target={"_blank"}>
            <img className={styles.iconSize} src={instagram} alt={"instagram"} />
          </a>
          <img className={styles.iconSize} src={email} alt={"email"} />
        </div>
      </div>
    </nav>
  );
};

export default SideDrawer;
