import React from "react";

import styles from "./Footer.module.css";

import facebook from "../../assets/footer/facebook.svg";
import instagram from "../../assets/footer/Instagram.svg";
import email from "../../assets/footer/Email.svg";

const Footer = () => {
  return (
    <div className={"container-flex"}>
      <div className={styles.footerContainer}>
        <div className={styles.footerItem}>
          <a href="https://facebook.com/andrea.traina.50" target={"_blank"}>
          <img src={facebook} alt={"facebook"} />
          </a>
        </div>
        <div className={styles.footerItem}>
          <a href="https://instagram.com/myeuropeanvacay/" target={"_blank"}>
            <img src={instagram} alt={"instagram"} />
          </a>
        </div>
        <div className={styles.footerItem}>
          <a href={"contact-us"}>
          <img src={email} alt={"email us"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
